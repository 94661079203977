<template>
  <div class="card">
    <q-img
      :src="urlAwsBucket + content.image_path"
      spinner-color="default-pink"
      spinner-size="82px"
      no-native-menu
      @click="
        () => {
          if (!enableActionRemove) {
            useImage(content.id, store.state.userdata.id);
            $emit('upload');
          }
        }
      "
      :class="{ 'cursor-pointer': !enableActionRemove }"
    >
      <q-tooltip v-if="!enableActionRemove">
        Clique na imagem para escolher o seu novo avatar
      </q-tooltip>
      <q-btn
        v-show="enableActionRemove"
        color="default-pink"
        class="relative all-pointer-events curso-pointer"
        style="top: 10px; left: 81%"
        icon="delete"
        @click="
          () => {
            removeImage(content.id, content.image_path);
          }
        "
        round
        size="sm"
      />
    </q-img>
  </div>
</template>

<script>
/* Quasar */
import { useQuasar } from "quasar";

//----UTILS
import { ENV  } from "@/utils/env";

/* Services */

import UserServices from "@/services/UserServices";

/* Vue */
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "CardImg",
  emits: ["upload"],
  props: {
    enableActionRemove: {
      type: Boolean,
      required: true,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  setup({ content }, { emit }) {
    /* Constants */
    const $q = useQuasar();
    const urlAwsBucket =ENV.URL_AWS_BUCKET;
    const userServices = new UserServices();
    const route = useRouter();

    /* Store */

    const store = useStore();

    /* Functions */

    function removeImage(id, image_path) {
      $q.notify({
        message: "Você deseja remover essa imagem?",
        color: "red-9",
        position: "top",
        actions: [
          {
            label: "Sim",
            color: "yellow",
            handler: async () => {
              await userServices
                .removeAvatar(id, image_path)
                .then((response) => {
                  $q.notify({
                    message: `A imagem foi removida com sucesso!`,
                    color: "green",
                    position: "top",
                  });
                  emit("upload");
                })
                .catch((error) => {
                  console.log(error);
                  $q.notify({
                    message:
                      "Não houve êxito ao remover a imagem, tente novamente mais tarde!",
                    color: "red-9",
                    position: "top",
                  });
                });
            },
          },
        ],
      });
    }

    function useImage(id, userId) {
      $q.notify({
        message: "Você deseja utilizar essa imagem como avatar?",
        color: "red-9",
        position: "top",
        actions: [
          {
            label: "Sim",
            color: "yellow",
            handler: async () => {
              await userServices
                .addAvatar(id, userId)
                .then((response) => {
                  if (response.data) {
                    $q.notify({
                      message: `A imagem foi adicionado com sucesso! `,
                      color: "green",
                      position: "top",
                    });
                  }
                  route.push("edit-student");
                })
                .catch((error) => {
                  $q.notify({
                    message:
                      "A imagem não foi adicionado com sucesso, por favor tente mais tarde!",
                    color: "red-9",
                    position: "top",
                  });
                });
            },
          },
        ],
      });
    }
    return {
      /* Functions */
      removeImage,
      useImage,
      /* constants */
      urlAwsBucket,
      store,
    };
  },
};
</script>

<style scoped>
.q-img {
  width: 200px;
  height: 200px;
}
.card {
  padding: 10px;
}
</style>
