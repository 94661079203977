<template>
  <div class="q-pa-md q-mt-md container-gallery">
    <Mural :breadcrumbs="breadcrumbs" route="edit-student"></Mural>
    <section class="gallery">
      <div v-for="(data, index) in pagination" :key="index">
        <CardImg :enableActionRemove="false" :content="data" />
      </div>
    </section>
    <q-pagination
      color="default-pink"
      direction-links
      boundary-links
      v-model="current"
      :min="currentPage"
      :max="Math.ceil(allAvatars?.length / totalItemsPerPage)"
      class="q-mt-md self-end q-mr-xl"
    />
  </div>
</template>

<script>
/* Components */
import Mural from "@/components/Mural.vue";
import CardImg from "@/components/admin/company/cards/CardImg.vue";

/* Vue */
import { ref, onMounted, computed } from "vue";

/* Services */

import UserServices from "@/services/UserServices";

/* Quasar */
import { useQuasar } from "quasar";
export default {
  components: {
    Mural,
    CardImg,
  },
  setup(props) {
    /* constants*/
    const breadcrumbs = ref(["Galeria de imagens"]);
    const currentPage = ref(1);
    const current = ref(1);
    const totalItemsPerPage = ref(12);
    const allAvatars = ref([]);
    const $q = useQuasar();

    /* Services */
    const userServices = new UserServices();

    /* LifeCycles */
    const pagination = computed(() => {
      return allAvatars.value.slice(
        (current.value - 1) * totalItemsPerPage.value,
        (current.value - 1) * totalItemsPerPage.value + totalItemsPerPage.value
      );
    });

    onMounted(() => {
      getPhotos();
    });

    /* Functions */

    async function getPhotos() {
      userServices
        .getAvatars()
        .then((response) => {
          allAvatars.value = response.data;
          if (response.data.length == 0) {
            $q.notify({
              message: `Você ainda não possui nenhuma imagem cadastrada!
               Por favor aguarde que o seu adminstrador cadastre alguma imagem.`,
              color: "red-9",
              position: "top",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return {
      /* Functions */
      getPhotos,
      /* constants */
      breadcrumbs,
      totalItemsPerPage,
      current,
      currentPage,
      allAvatars,
      /* LifeCycles */
      pagination,
    };
  },
};
</script>

<style scoped>
.container-gallery {
  display: flex;
  flex-direction: column;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(70px, 200px));
  margin: 1.875rem 0;
  justify-content: center;
  gap: 30px;
}
</style>
